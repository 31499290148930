import React, { useState, useRef, useEffect } from "react"
import ClientDetails from "./components/ClientDetails"
import { AiOutlineDownload } from "react-icons/ai"
import Dates from "./components/Dates"
import Footer from "./components/Footer"
import Header from "./components/Header"
import MainDetails from "./components/MainDetails"
import Notes from "./components/Notes"
import Table from "./components/Table"
import TableForm from "./components/TableForm"
import ReactToPrint from "react-to-print"

function App() {
  const [headerText, setHeaderText] = useState("")
  const [selects, setSelects] = useState("")
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [bankName, setBankName] = useState("")
  const [bankAccount, setBankAccount] = useState("")
  const [website, setWebsite] = useState("")
  const [clientName, setClientName] = useState("")
  const [clientAddress, setClientAddress] = useState("")
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [clientPhoneNumber, setClientPhoneNumber] =useState("")
  const [pickupPoint, setpickupPoint] = useState("")
  const [seatnumber, setseatnumber] = useState("")
  const [invoiceDate, setInvoiceDate] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [notes, setNotes] = useState("")
  const [description, setDescription] = useState("")
  const [quantity, setQuantity] = useState("")
  const [price, setPrice] = useState("")
  const [amount, setAmount] = useState("")
  const [amountPaid, setAmountPaid] = useState("")
  const [list, setList] = useState([])
  const [isShownCouple, setisShownCouple] = useState(false);
  const [isShownDiscount, setisShownDiscount] = useState(false);
  const [isShownExtraCharge, setisShownExtraCharge] = useState(false);
  const [isShownVAT, setisShownVAT] = useState(false);
  const [total, setTotal] = useState(0)
  const [balanceDue, setBalanceDue] = useState(0)
  const [couple, setCouple] = useState("")
  const [discount, setDiscount] = useState("")
  const [extra, setExtra] = useState("")
  const [VAT, setVAT] = useState("")
  const [grandTotal, setgrandTotal] = useState ("")
  const [width] = useState(641)


  const componentRef = useRef()

  const handleClick = event => {
    setisShownCouple(current => !current);
  };

  const handleClickDis = event => {
    setisShownDiscount(current => !current);
  };

  const handleClickExtra = event => {
    setisShownExtraCharge(current => !current);
  };

  const handleClickVAT = event => {
    setisShownVAT(current => !current);
  };

  const handlePrint = () => {
    window.print()
  }
 


  useEffect(() => {
    if (window.innerWidth < width) {
      alert("Place your phone in landscape mode for the best experience")
    }
  }, [width])

  const invoicceNumber = (Math.floor(100000 + Math.random() * 100000));

  return (

    
    <>
     
      <main className="m-5 p-5 xl:grid grid-cols-2 gap-10 xl:items-start">
        <section>

          
          <div className="bg-white p-5 rounded shadow">
            {/* name, address, email, phone, bank name, bank account number, website client name, client address, invoice number, invoice date, due date, notes */}
            <div className="flex flex-col justify-center">

            <div className="flex flex-col">
                  <label htmlFor="headerText">Type:</label>


                  <select value={selects}  onChange={e=>setSelects(e.target.value)} >
                  <option defaultValue> ----Select---- </option>
                  <option>INVOICE</option>
                  <option>e-Ticket</option>
                  <option>BILL</option>  
                  </select>
                  
                  </div>

                  {/* <button onClick={} onChange={(e) => (e.target.value)}>
                    Click me
                  </button> */}

                  <div className="flex flex-col">
                  <label htmlFor="invoicceNumber">Invoice Number:</label>
                  <input
                    type="text"
                    name="invoicceNumber"
                    id="invoiceNumber"
                    placeholder="Enter Invoice Number "
                    autoComplete="off"
                    value={invoicceNumber}
                    
                  />
                  </div>

        

              <article className="md:grid grid-cols-2 gap-10">
                <div className="flex flex-col">
                  <label htmlFor="clientName">Enter your client's name</label>
                  <input
                    type="text"
                    name="clientName"
                    id="clientName"
                    placeholder="Enter your client's name"
                    autoComplete="off"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="clientAddress">
                    Enter your client's address
                  </label>
                  <input
                    type="text"
                    name="clientAddress"
                    id="clientAddress"
                    placeholder="Enter your client's address"
                    autoComplete="off"
                    value={clientAddress}
                    onChange={(e) => setClientAddress(e.target.value)}
                  />
                </div>
              </article>

              <article className="md:grid grid-cols-2 gap-10">
                <div className="flex flex-col">
                  <label htmlFor="pickupPoint">Pickup Point</label>
                  <input
                    type="text"
                    name="pickupPoint"
                    id="pickupPoint"
                    placeholder="Pickup Point"
                    autoComplete="off"
                    value={pickupPoint}
                    onChange={(e) => setpickupPoint(e.target.value)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="clientPhoneNumber">Contact Number</label>
                  <input
                    type="text"
                    name="clientPhoneNumber"
                    id="clientPhoneNumber"
                    placeholder="Client Contact Number"
                    autoComplete="off"
                    value={clientPhoneNumber}
                    onChange={(e) => setClientPhoneNumber(e.target.value)}
                  />
                </div>
              </article>

              <article className="md:grid grid-cols-2 gap-10">
              <div className="flex flex-col">
                  <label htmlFor="invoiceDate">Booking Date</label>
                  <input
                    type="date"
                    name="invoiceDate"
                    id="invoiceDate"
                    placeholder="Invoice Date"
                    autoComplete="off"
                    value={invoiceDate}
                    onChange={(e) => setInvoiceDate(e.target.value)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="dueDate">Departure Date</label>
                  <input
                    type="date"
                    name="dueDate"
                    id="dueDate"
                    placeholder="Invoice Date"
                    autoComplete="off"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </div>
              </article>

              <article className="md:grid grid-cols-2 gap-10">
              <div className="flex flex-col">
                  <label htmlFor="pickupPoint">Seat Number</label>
                  <input
                    type="text"
                    name="seatNumber"
                    id="seatnumber"
                    placeholder="Seat Number"
                    autoComplete="off"
                    value={seatnumber}
                    onChange={(e) => setseatnumber(e.target.value)}
                  />
                </div>
                 </article>



              {/* This is our table form */}
              <article>
                <TableForm
                  description={description}
                  setDescription={setDescription}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  price={price}
                  setPrice={setPrice}
                  amount={amount}
                  setAmount={setAmount}
                  list={list}
                  setList={setList}
                  total={total}
                  setTotal={setTotal}
                  amountPaid={amountPaid}
                  setAmountPaid={setAmountPaid}
                />
              </article>
             
            
            
             <div>
             <div className="flex flex-cols between">
            <div className="flex justify-inline form-check">
            <input className="form-check-input 
            appearance-none h-4 w-4 border border-gray-300 
            rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 
            focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat 
            bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" onClick={handleClick} value="couple" id="flexCheckDefault1"></input>
            <label className="form-check-label inline-block text-gray-800" htmlFor="flexCheckChecked">
              Couple
            </label>
            </div>

            <div className="flex justify-inline form-check  mx-6">
            <input className="form-check-input 
            appearance-none h-4 w-4 border border-gray-300 
            rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 
            focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat 
            bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" onClick={handleClickExtra} value="extra" id="flexCheckDefault2"></input>
            <label className="form-check-label inline-block text-gray-800" htmlFor="flexCheckChecked">
            Extra Charges
            </label>
            </div>

            <div className="flex justify-inline form-check  mx-6">
            <input className="form-check-input 
            appearance-none h-4 w-4 border border-gray-300 
            rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 
            focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat 
            bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" onClick={handleClickVAT} value="extra" id="flexCheckDefault2"></input>
            <label className="form-check-label inline-block text-gray-800" htmlFor="flexCheckChecked">
            VAT
            </label>
            </div>

            <div className="flex justify-inline form-check">
            <input className="form-check-input 
            appearance-none h-4 w-4 border border-gray-300 
            rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 
            focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat 
            bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" onClick={handleClickDis} value="discount" id="flexCheckDefault"></input>
            <label className="form-check-label inline-block text-gray-800" htmlFor="flexCheckChecked">
            Discount
            </label>
            </div>

      </div>

      <article className="md:grid grid-cols-3 gap-10">
       
      {isShownCouple && (
        <div className="flex flex-col">
        <label htmlFor="couple">Couple Charge:</label>
        <input
          type="number"
          name="couple"
          id="couple"
          placeholder="Enter Couple Charge"
          autoComplete="off"
          value={couple}
          onChange={(e) => setCouple(e.target.value)}
        />
      </div>
      )}

{isShownExtraCharge && (
        <div className="flex flex-col">
        <label htmlFor="extra">Extra Charges:</label>
        <input
          type="number"
          name="extraCharges"
          id="extraCharges"
          placeholder="Enter any Extra Charges"
          autoComplete="off"
          value={extra}
          onChange={(e) => setExtra(e.target.value)}
        />
      </div>
      )}

  {isShownVAT && (
        <div className="flex flex-col">
        <label htmlFor="extra">VAT Amount:</label>
        <input
          type="number"
          name="extraCharges"
          id="extraCharges"
          placeholder="Enter any Extra Charges"
          autoComplete="off"
          value={VAT}
          onChange={(e) => setVAT(e.target.value)}
        />
      </div>
      )}


{isShownDiscount && (
        <div className="flex flex-col">
        <label htmlFor="discount">Discount:</label>
        <input
          type="number"
          name="discount"
          id="discount"
          placeholder="Enter Discount Amount"
          autoComplete="off"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
        />
      </div>
      )}


      </article>
     
    </div>

    <div className="flex flex-col">
              <label htmlFor="description">Advance Paid</label>
              <input
                type="number"
                name="amountPaid"
                id="amountPaid"
                placeholder="Total amount Paid"
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
              />
            </div>



            {/* <article className="md:grid grid-cols-2 gap-10">
                <div className="flex flex-col">
                  <label htmlFor="name">Your full name</label>
                  <input
                    type="text"
                    name="text"
                    id="name"
                    placeholder="Enter your name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="address">Enter your address</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Enter your address"
                    autoComplete="off"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </article> */}

              {/* <article className="md:grid grid-cols-3 gap-10">
                <div className="flex flex-col">
                  <label htmlFor="email">Enter your email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="website">Enter your website</label>
                  <input
                    type="url"
                    name="website"
                    id="website"
                    placeholder="Enter your website"
                    autoComplete="off"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="phone">Enter your phone</label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Enter your phone"
                    autoComplete="off"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </article> */}

              {/* <article className="md:grid grid-cols-2 gap-10">
                <div className="flex flex-col">
                  <label htmlFor="bankName">Enter your bank name</label>
                  <input
                    type="text"
                    name="bankName"
                    id="bankName"
                    placeholder="Enter your bank name"
                    autoComplete="off"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="bankAccount">
                    Enter your bank account number
                  </label>
                  <input
                    type="text"
                    name="bankAccount"
                    id="bankAccount"
                    placeholder="Enter your bank account number"
                    autoComplete="off"
                    value={bankAccount}
                    onChange={(e) => setBankAccount(e.target.value)}
                  />
                </div>
              </article> */}

           
              {/* <button
              onClick={() => setShowInvoice(true)}
              className="bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300"
            >
              Preview Invoice
            </button> */}
            </div>
          </div>
        </section>

        {/* Invoice Preview */}
        <div className="invoice__preview bg-white p-5 rounded">
         
          <div ref={componentRef} className="p-5">
            <Header 
            handlePrint={handlePrint}
            headerText={headerText}
            selects={selects}
            setSelects={setSelects}
            invoiceNumber={invoiceNumber}
            invoicceNumber={invoicceNumber} />

            <MainDetails name={name} address={address} />

            

            <Dates
              clientPhoneNumber={clientPhoneNumber}
              clientName={clientName}
              clientAddress={clientAddress}
              invoiceDate={invoiceDate}
              dueDate={dueDate}
              pickupPoint={pickupPoint}
              balanceDue={balanceDue}
              setBalanceDue={setBalanceDue}
              quantity={quantity}
              total={total}
              setTotal={setTotal}
              amountPaid={amountPaid}
              setAmountPaid={setAmountPaid}
              grandTotal={grandTotal}
              setgrandTotal={setgrandTotal}
              seatnumber={seatnumber}
              setseatnumber={setseatnumber}
            />

              <ClientDetails
              clientName={clientName}
              clientAddress={clientAddress}
            />

            <Table
              description={description}
              quantity={quantity}
              price={price}
              amount={amount}
              list={list}
              setList={setList}
              total={total}
              setTotal={setTotal}
              amountPaid={amountPaid}
              setAmountPaid={setAmountPaid}
              couple={couple}
              setCouple={setCouple}
              isShownCouple={isShownCouple}
              setisShownCouple={setisShownCouple}
              discount={discount}
              isShownDiscount={isShownDiscount}
              setisShownDiscount={setisShownDiscount}
              extra={extra}
              VAT={VAT}
              isShownExtraCharge={isShownExtraCharge}
              setisShownExtraCharge={setisShownExtraCharge}
              isShownVAT={isShownVAT}
              setisShownVAT={setisShownVAT}
              grandTotal={grandTotal}
              setgrandTotal={setgrandTotal}
            
            />

            <Notes 
            notes={notes}
            clientName={clientName}
            list={list}
            
           />

            <Footer
              name={name}
              address={address}
              website={website}
              email={email}
              phone={phone}
              bankAccount={bankAccount}
              bankName={bankName}
              balanceDue={balanceDue}
              setBalanceDue={setBalanceDue}
            />
          </div>
          {/* <button
            onClick={() => setShowInvoice(false)}
            className="mt-5 bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300"
          >
            Edit Information
          </button> */}

            <ReactToPrint
            trigger={() => (
              <button className="bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300">
                <AiOutlineDownload />
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
       
      </main>
    </>
  )
}

export default App
