import React, {useState, useEffect } from "react"
export default function Dates({setBalanceDue, pickupPoint, 
  total, 
  amountPaid, 
  invoiceDate, 
  dueDate, 
  clientPhoneNumber, 
  balanceDue,
  grandTotal,
  clientName,
  clientAddress,
  seatnumber
  
 }) {
  
  useEffect(() => {
    const calculateDue = (balanceDue) => {
      setBalanceDue (grandTotal - amountPaid)
    }

    calculateDue(balanceDue)
  })
  return (
    <>

    

      <article className=" flex flex-row mt-10 mb-14 flex items-start justify-between">



        <section className="flex flex-col items-start justify-start">
        <h2 className="font-bold text-lg uppercase mb-1">Travel Nepal</h2>
        <p className="text-sm">A: Urlabari, Morang</p>
        <p className="text-sm">P: +977 9801708396</p>
      </section>

      <div className="flex flex-row">
        <ul>
        <li className="p-1 text-sm bg-gray-100 text-left">
            <span className="font-bold">Booking Date:</span> 
          </li>
          <li className="p-1 text-sm  text-left">
            <span className="font-bold">Departure Date:</span> 
          </li>
          
          <li className="p-1 text-sm  text-left">
            <span className="font-bold">Contact number:&nbsp;</span>
          </li>
          <li className="p-1 text-sm  text-left">
            <span className="font-bold">Pickup Point:&nbsp;</span> 
          </li>
          <li className="p-1 text-sm  text-left">
            <span className="font-bold">Seat Number:&nbsp;</span> 
          </li>
          <li className="p-1 text-sm bg-gray-200 text-left mt-6">
            <span className="font-bold text-2xl">Balance Due:</span> 
          </li>
        </ul>

        

        <ul>
          <li className="p-1 text-sm bg-gray-100">  <span className="ml-8 "> {invoiceDate}  </span> </li>
          <li className="p-1 text-sm"> <span className="ml-8 "> {dueDate} </span>  </li>
          <li className="p-1 text-sm">   <span className="ml-8 "> {clientPhoneNumber} </span> </li>
          
          <li className="p-1 text-sm"> <span className="ml-8 "  >{pickupPoint} </span> </li>
          <li className="p-1 text-sm"> <span className="ml-8 "  >{seatnumber} </span> </li>
          <li className="p-1 bg-gray-200 text-2xl mt-6"> <span className="ml-8 ">Rs. {balanceDue.toLocaleString()} </span> </li>
        </ul>
          
           </div>
      </article>

      <article className=" flex flex-row mt-10 mb-14 flex items-start justify-between clientCustom">
      <div className="flex flex-cols items-start justify-start"> 
        <h5 className="text-sm font-bold text-gray-800 mb-1">Name:
       <p className="mt-2">
        {clientName}
        </p>
        </h5>

        <h5 className="text-sm font-bold text-gray-800 mb-1 ml-10">Address:
       <p className="mt-2">
        {clientAddress}
        </p>
        </h5>
        </div>
        </article>
       

    </>
  )
}
