import React from "react"
import { useState, useEffect} from "react"
export default function Table({ list, total, amountPaid, couple, discount, extra, grandTotal, setgrandTotal, setisShownCouple, isShownCouple, isShownDiscount, setisShownDiscount, isShownExtraCharge, setisShownExtraCharge, VAT, isShownVAT, setisShownVAT }) {


  useEffect(() => {
    const calculateGrandTotal = (grandTotal) => {
      setgrandTotal ( Number(total) + Number(couple) + Number(extra) -  + Number(discount) + Number((VAT)) ) ;
    }

    calculateGrandTotal(grandTotal)
  })


  const handleClick = event => {
    setisShownCouple(current => !current);
  };

    if (couple<0) {
      isShownCouple(false);
    } 


    const handleClickDis = event => {
      setisShownDiscount(current => !current);
    };
  
    if (discount<0) {
      isShownDiscount(false);
    } 
    
      const handleClickExtra = event => {
        setisShownExtraCharge(current => !current);
      };

      if (extra<0) {
        isShownExtraCharge(false);
      } 
    
    
    
    return (
    <>
      <table width="100%" className="mb-10">
        <thead>
          <tr className="table-row-c p-1 ml-10">
            <td className="font-bold">Description</td>
            <td className="font-bold">Pax</td>
            <td className="font-bold">Per Head</td>
            <td className="font-bold">Amount</td>
          </tr>
        </thead>
        {list.map(({ id, description, quantity, price, amount }) => (
          <React.Fragment key={id}>
            <tbody>
              <tr className="h-10">
                <td>{description}</td>
                <td>{quantity}</td>
                <td>{price}</td>
                <td>{amount}</td>
              </tr>
            </tbody>
          </React.Fragment>
        ))}
      </table>

      <div>
       
          <article className="mt-10 flex text-gray-800 items-end justify-end tabledataCustom">
        <div className="flex flex-cols">
        <ul>
        <li className="p-1 bg-gray-100 text-left">
            <span className="font-bold">Sub Total:</span> 
          </li>


          {isShownCouple && (
            <li className="p-1  text-left">
            <span className="font-bold">Couple Charge:</span> 
          </li>
          )}

          {isShownExtraCharge && (
          <li className="p-1  text-left">
            <span className="font-bold">Extra Charge:</span> 
          </li>
          )}


        {isShownDiscount && (
          <li className="p-1  text-left">
            <span className="font-bold">Discount:</span> 
          </li>
          )}

      {isShownVAT && (
          <li className="p-1  text-left">
            <span className="font-bold">VAT Amount:</span> 
          </li>
          )}

          <li className="p-1  text-left">
            <span className="font-bold">Total:</span> 
          </li>

          <li className="p-1  text-left">
            <span className="font-bold">Advance Paid:</span> 
          </li>
          
          
         
        

        </ul>

        <ul>
          <li className="p-1 bg-gray-100">  <span className="ml-8 ">  Rs. {total}  </span> </li>
          {isShownCouple && ( <li className="p-1"> <span className="ml-8 ">  Rs. {couple} </span>  </li>   )}
          {isShownExtraCharge && ( <li className="p-1"> <span className="ml-8 ">  Rs. {extra} </span>  </li>   )}
          {isShownDiscount && ( <li className="p-1"> <span className="ml-8 "> ( Rs. {discount} ) </span>  </li>   )}
          {isShownVAT && ( <li className="p-1"> <span className="ml-8 ">  Rs. {VAT} </span>  </li>   )}
          <li className="p-1"> <span className="ml-8 ">  Rs. {grandTotal} </span>  </li>
          <li className="p-1"> <span className="ml-8 ">  Rs. {amountPaid} </span>  </li>
        </ul>
          
           </div>
        
      </article>

      </div>
    </>
  )
}
