export default function Notes({ 
   clientName,
   list,

}) {
  
  return (
    <>
    
      <section className="mt-10"> 
      <div>
      <label className="text-sm text-bold text-black mb-6">Dear {clientName}, </label>
      </div>
      {list.map(({ description, quantity }) => (
        <p className=" text-sm mb-6">
         We're glad you've placed your trust in Travel Nepal and have chosen us as your preferred Travel Partner. We are pleased to confirm that {description} for {quantity} pax has been booked under your name.
        See you soon,
        <p className="font-bold text-gray-900">
        -Team Travel Nepal !!
        </p>
        </p>
         ))}
        <label className="text-sm text-bold text-black mb-6">TERMS & CONDITIONS</label>
        <p className=" text-sm">
        <ul><li class="mb-2">1. It is mandatory for the participants to follow the instructions of the tour guide, taking care of the time mentioned in the  tour schedule throughout the duration of the tour. </li><li class="mb-2">2. Flamboyant clothing and consumption of drugs and alcohol throughout the journey is strictly prohibited. </li><li class="mb-2">3. If the tour is extended due to political, natural, etc. reasons, the financial expenses of the extended time will have to be  borne by the participants themselves. </li><li class="mb-2">4. The participants of the tour have to protect their belongings themselves, the company or staff will not be responsible if they are lost. </li>
    <li class="mb-2">5.
The tour itinerary may be adjusted or rescheduled by the company as necessary to accommodate changing conditions or requirements.
</li>

     <li class="mb-2">6. After the tour starts, cancellations for any reason are not acceptable, and the company will not refund any amount. The tour's viability is dependent on the number of passengers.
</li>

     <li class="mb-2">7. After the tour starts, cancellations for any reason are not acceptable, and the company will not refund any amount. The tour's viability is dependent on the number of passengers.</li>

         
          <li class="mb-2">8. 
The company is not responsible for the health of passengers, including sudden illness or death, whether pre-existing or arising during the tour. Passengers are responsible for their own well-being. However, vehicle insurance covers incidents occurring inside the vehicle. This includes unfortunate and unforeseeable events such as self-harm or suicide, which are personal decisions beyond the company's control.
</li>


     <li class="mb-2">9. If the tour is extended without following the instructions of the tour guide according to the tour schedule, all the expens s of the extended days shall be borne by the participants.</li>


    
    <li class="pb-4">10.Together we will make this visit disciplined, beautiful, enlightening and memorable. Any uncomfortable situation during the tour If it comes, we will face it together. </li></ul>
        </p>
        
      </section>
    </>
  )
}
